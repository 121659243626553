import { useRouter } from 'next/router';
import { l10n } from './l10n';
import { supportedLanguages } from './types';
var convertLocaleToSupportedLanguage = function (locale) {
    if (locale && supportedLanguages.includes(locale)) {
        return locale;
    }
    return 'ja';
};
var useL10n = function () {
    var router = useRouter();
    var language = convertLocaleToSupportedLanguage(router === null || router === void 0 ? void 0 : router.locale);
    return l10n[language];
};
export { l10n, supportedLanguages, useL10n };
